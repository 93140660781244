// App main
import 'unfonts.css';
import 'virtual:svg-icons-register';
import '@/css/app.pcss';
import 'swiper/css';
import 'lazysizes/plugins/bgset/ls.bgset';
import 'lazysizes';
import {Autoplay, Navigation, Pagination, Swiper} from 'swiper';
import Alpine from 'alpinejs';

Swiper.use([Navigation, Pagination, Autoplay]);
window['Swiper'] = Swiper;

document.documentElement.classList.remove('no-js');
window['app'] = {
    slideshow() {
        return {
            progress: 0,
            setup(el) {
                const modul = new Swiper(el, {
                    slidesPerView: "auto",
                    grabCursor: true,
                    scrollbar: {
                        el: ".swiper-scrollbar",
                    },
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev'
                    }
                });

                modul.on('slideChange', (swiper) => {
                    this.progress = parseInt(swiper.progress * 100);
                });
            }
        }
    }
};
Alpine.start();
